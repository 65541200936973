/*
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-16 16:00:50
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-16 16:00:56
 * @FilePath: \mid-autumn\src\utils\three\tween.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import TWEEN from '@tweenjs/tween.js';

export default {
  install: function (Vue) {
    Vue.prototype.$tween = this;
    Vue.prototype.$tweener = TWEEN;
  },
  pause: function (tw) {
    tw.pause();
  },
  fade: function (target, to, during, delay, easing, onUpdate, onComplete) {
    if (!target || !to) {
      return;
    }
    if (during == null) {
      during = 260;
    }

    const tw = new TWEEN.Tween(target).to(to, during);
    if (delay) tw.delay(delay);
    tw.easing(easing || TWEEN.Easing.Linear.None);
    if (onUpdate) tw.onUpdate(onUpdate);
    if (onComplete) tw.onComplete(onComplete);
    tw.start();
    return tw;
  },
};
